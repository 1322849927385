import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import styled from 'styled-components'

const Section = styled.section`
`
const Container = styled.div`
`
const Columns = styled.div`
    width: 100%;
    padding: 0 2em;
`
const SubColumns = styled.div`
`
const PageText = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Sawarabi+Mincho&display=swap');
  font-family: 'Sawarabi Mincho', sans-serif;
  line-height: 1.5;
  word-wrap: break-word;
  overflow-wrap: break-word;
`
const Title = styled.h2`
  @import url('https://fonts.googleapis.com/css?family=Sawarabi+Mincho&display=swap');
  font-family: 'Sawarabi Mincho', sans-serif;
  text-align: center;
  padding: 30px 0;
  margin: 0 auto;
`

export const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <Section className="section section--gradient" style={{backgroundColor: `#faf9f6`}}>
      <Title className="title is-size-3 has-text-weight-bold is-bold-light">
        {title}
      </Title>
      <Container className="container">
        <Columns>
          <SubColumns>
              <br />
              <PageText>
                <PageContent className="content" content={content} />
              </PageText>
          </SubColumns>
        </Columns>
      </Container>
    </Section>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
